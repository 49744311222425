<template>
  <v-container class="login">
    <loading v-if="loading"></loading>
    <div id="login-container" class="basePadding" v-else>


      <amplify-auth
          class="d-flex justify-center"
          :initial-page="initialPage"
          @on-authentication="onAuthentication"
      />

      <v-snackbar
        app
        :right="true"
        :top="true"
        :timeout="-1"
        v-model="showIdleLogout">
         You've been logged out due to inactivity.
        <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="showIdleLogout = false"><v-icon>close</v-icon></v-btn>
      </v-snackbar>
      <v-snackbar
        app
        :right="true"
        :top="true"
        :timeout="-1"
        v-model="showCredentialsExpiredLogout">
        Your credentials have expired.  Please close then reopen the page.
        <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="showCredentialsExpiredLogout = false"><v-icon>close</v-icon></v-btn>
      </v-snackbar>
      <v-snackbar
          :right="true"
          :top="true"
          :timeout="15000"
          v-model="error"
      >
        <span>{{errorMessage}}</span>
        <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="error = false"><v-icon>close</v-icon></v-btn>
      </v-snackbar>

      <v-dialog v-model="showResetPasswordDialog" max-width="600px">
        <v-card>
          <v-card-title>
            Reset Password
          </v-card-title>
          <v-card-text>
            If your account is setup using your <b>Google account, no email will be sent</b>.
            <br />
            <br />
            Instead reset your Google password or if you just want access to Truple try logging in using your Google account.
          </v-card-text>
          <v-card-actions>
            <v-btn class="primary" @click="showResetPasswordDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
  /* eslint-disable no-console */

  import Auth from '@aws-amplify/auth'

  import StoreConstants from "../StoreConstants";
  import Loading from './Loading'
  import SegmentTracking from "../services/SegmentTracking";
  import AndroidInterface from "../services/AndroidInterface";
  import AmplifyAuth from "@/components/amplify/AmplifyAuth";
  import AuthPages from "@/components/amplify/AuthPages"

  export default {
    name: 'login',
    components: {
      AmplifyAuth,
      Loading
    },
    data () {
      return {
        error: false,
        errorMessage: '',
        initialPage: AuthPages.signUp,
        showResetPasswordDialog: false,
        msg: 'login',
        email: null,
        loading: false,
        renderComplete: false,
        // showEmailNotVerifiedDialog: false,
        domain: process.env.VUE_APP_DOMAIN,
        showIdleLogout: this.$route.query.idleLogout,
        showCredentialsExpiredLogout: this.$route.query.credentialsExpired,
        authState: null,
        user: null
      }
    },
    created() {
    },

    methods: {
      // async emailAddressNotVerifiedOkay() {
      //   this.showEmailNotVerifiedDialog = false;
      //   await this.auth.logoutAwait()
      //   this.login()
      // },
      login() {
        // Auth.federatedSignIn()
        // lock.show()
        // document.getElementsByClassName('auth0-lock-cred-pane-internal-wrapper')[0].style.height = 'auto'
      },
      logError(err) {
        console.log('error')
        console.log(JSON.stringify(err, null, 2))
      },
      async loadStore() {
        let p1 = this.$store.dispatch(StoreConstants.actions.loadAccount)
        let p2 = this.$store.dispatch(StoreConstants.actions.loadDevices)
        await Promise.all([p1, p2])
      },
      cleanUpHash(url) {
        if(url) {
          //auth0
          let hashIndex = url.indexOf('access_token')
          if(hashIndex !== -1) {
            let ret = url.substring(0, hashIndex)
            if(ret === '/#') {
              ret = '/'
            }
            console.log(`cleanUpHash\n${url}\n${ret}`)
            return ret
          }

          //amplify
          let codeIndex = url.indexOf('code')
          if(codeIndex !== -1) {
            let ret = url.substring(0, codeIndex)
            if(ret === '/?') {
              ret = '/'
            }
            console.log(`cleanUpHash\n${url}\n${ret}`)
            return ret
          }
        }
        return url
      },
      async onAuthentication(cu) {
        localStorage.setItem('has-logged-in-before', 'true');

        this.loading = true

        try {
          let currentUserSession = cu || await Auth.currentSession()

          if(!currentUserSession.idToken.payload.email_verified) {
            throw new Error('email is not yet verified')
          }

          this.email = currentUserSession.idToken.payload.email.trim().toLowerCase()

          if(this.$route.query.loginAs && this.$route.query.loginAs.toLowerCase() !== this.email) {
            if(this.$store.state.inApp) {
              await this.auth.logoutAwait()
              this.$swal(`You need to login with ${this.$route.query.loginAs}.  You've been logged out of ${this.email}.`)
              location.reload(true)
              this.loading = false
              return
              // window.location.href = window.location.origin + '/account?subscribe=true&inApp=true&loginAs=' + this.$route.query.loginAs
            } else if(window.location.href.toLowerCase().indexOf('dailyemailimages') === -1) { //recipient is set when dailyEmailImages is loaded
              this.$swal(`You're logged in with ${this.email} but your android device is connected to the account under ${this.$route.query.loginAs}.  If you wish to modify the settings for the account associated with your android device, please logout and log back in with ${this.$route.query.loginAs}.`)
            }
          }

          try {
            await this.auth.setCurrentSession(currentUserSession)
          } catch(e) {
            console.log(e)
            // alert('There was an issue logging you in.  Please try again.')
            // await this.logoutAwait('login error')
            if(e.toString().indexOf('Logins don\'t match') !== -1) {
              location.reload() //this typically fixes the issue
            }
          }

          let accountCreationRequest = {
            email: this.email,
            tokenId: currentUserSession.idToken.jwtToken,
            referrer: this.$store.state.referrer,
            href: this.$store.state.href,
            product: process.env.VUE_APP_PRODUCT
          }

          console.log('calling createAccountAwait')
          let accountCreationKey = `account.creation.${this.email}`
          let accountAlreadyCreated = localStorage.getItem(accountCreationKey)
          let result = {}

          if(accountAlreadyCreated) {
            //we know the account is already created
            result.status = 200
            result.data = {
              status: 'ALREADY_EXISTS'
            }

            //still make the call, just don't wait for it.
            this.api.createAccountAwait(accountCreationRequest).then(() => {})
          } else {
            try {
              result = await this.api.createAccountAwait(accountCreationRequest)

              SegmentTracking.track('AccountCreated', {
                category: 'Account',
                currency: 'USD',
                value: 0.00
              })

            } catch(e) {
              console.log(e)
              await this.auth.logoutAwait()
              setTimeout(async () => {
                  window.location.reload()
              }, 3000)
              return
            }

            console.log('response received: ' + result.status)
            localStorage.setItem(accountCreationKey, "true")
          }

          console.log(`verificationRedirect = ${localStorage.verificationRedirect}`)

          //check local storage first to see if a redirect existed before they were prompted to verify their account
          // otherwise use this.$route.query.redirect.  This helps especially with accountability users who create the
          // account just to be able to view screenshots.  They don't want to be redirected to /account?subscribe=true
          let redirect = this.$route.query.redirect

          if(sessionStorage.getItem('oAuthRedirect')) {
            redirect = sessionStorage.getItem('oAuthRedirect')
            sessionStorage.removeItem('oAuthRedirect')
          }

          if(localStorage.verificationRedirect && localStorage.verificationRedirect !== 'null' && localStorage.verificationRedirect !== 'undefined') {
            localStorage.verificationRedirect = localStorage.verificationRedirect.toLowerCase()
            if(localStorage.verificationRedirect.startsWith(process.env.VUE_APP_HOST)) {
              console.log('setting redirect to verification redirect of ' + localStorage.verificationRedirect)
              redirect = localStorage.verificationRedirect
            }
          }

          if(localStorage.verificationRedirect) {
            // console.log(`localStorage redirect to ${localStorage.verificationRedirect}`)
            console.log('clearning verification redirect of ' + localStorage.verificationRedirect)
            localStorage.verificationRedirect = '' //null gets saved as "null" string
          }

          if(redirect !== undefined && redirect !== null && redirect.length > 0 && result.status === 200) {

            // let newRedirectUrl = removeAuthQueryStringParameters(redirect);
            let newRedirectUrl = this.cleanUpHash(redirect)

            console.log(`redirect to ${newRedirectUrl}`)
            this.$router.replace(newRedirectUrl)

            this.loadStore()
          }
          else if (result.status === 200 && result.data.status === "SUCCESS") {
            console.log('redirect to account and subscribe')
            this.$router.replace('account?subscribe=true')
            this.loadStore()
          }
          else if (result.status === 200) {
            console.log('redirect to home by default')
            this.$router.replace('home')

            this.loadStore()
          } else {
            console.log(result)
            this.$swal('Issue logging in, please clear your cache and try again.  If you need help, please email support@truple.io')
            this.$router.replace('login')
          }

        } catch(e) {
          console.log(e)
          try {
            await Auth.signOut()
          } catch(ee) {
            console.log(ee)
          }
        }

        setTimeout(() => {
          this.loading = false
        }, 5000)
      },
      isInAppView() {
        return !!this.$route.query.idToken || !!this.$route.query.secureTokenPass
      },
      loginUsingPassedCredentials(idToken, domain, expiresAt, account, deviceId, deviceUUID, redirectUrl, showOwnScreenshots) {
        // let idToken = this.$route.query.idToken
        // let domain = this.$route.query.domain
        // let expiresAt = this.$route.query.expiresAt ? parseInt(this.$route.query.expiresAt) : 0
        // let account = this.$route.query.accountId
        // let deviceUUID = this.$route.query.deviceUUID
        // let redirectUrl = this.$route.query.redirect
        // let showOwnScreenshots = this.$route.query.showOwnScreenshots

        this.$store.commit(StoreConstants.commits.showOwnScreenshots, showOwnScreenshots)

        if(deviceId) {
          if(deviceId) {
            let currentDevice = decodeURIComponent(deviceId).split('').reverse().join('')
            localStorage.setItem('currentDevice', currentDevice)
          }
        }

        if(expiresAt < Date.now()) {
          this.showCredentialsExpiredLogout = true
        } else {
          console.log(`found credentials from app`)
          console.log(idToken)
          console.log(domain)
          console.log(expiresAt)
          console.log(account)
          console.log(deviceUUID)
          console.log(redirectUrl)

          this.$store.commit(StoreConstants.commits.setInAppCredentialsExpiresAt)

          const myAsyncHelper = async () => {
            if(redirectUrl && redirectUrl.toLowerCase() !== 'account') {
              this.$store.commit(StoreConstants.commits.setInApp)
            }

            try {
              await this.auth.appLogin(account, deviceUUID, domain, idToken, expiresAt)
            } catch(e) {
              console.log(e)
              // alert('There was an issue logging you in.  Please try again.')
              if(e.toString().indexOf('Logins don\'t match') !== -1) {
                location.reload() //this typically fixes the issue
              }
              // await this.logoutAwait('login error')
            }

            if(redirectUrl && redirectUrl.indexOf('home') !== -1) {
              console.log('loading store without waiting.')
              this.loadStore() //we don't want to wait for it on the home page.  Other AuthPages may depend on it--we haven't optimized them to not need to yet.
            } else {
              await this.loadStore()
            }


            redirectUrl = this.cleanUpHash(redirectUrl)
            console.log(`redirect to ${redirectUrl}`)
            await this.$router.replace(redirectUrl)

            this.loading = false
          }

          myAsyncHelper()
        }
      }
    },
    beforeCreate() {
      console.log('beforeCreate')
    },
    beforeDestroy() {

    },
    beforeMount() {
      if(!this.isInAppView()) {

        let hasLoggedInBefore = localStorage.getItem('has-logged-in-before')
        if(!hasLoggedInBefore) {
          hasLoggedInBefore = localStorage.getItem(`aws.cognito.identity-id.${process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID}`)
        }

        this.initialPage = hasLoggedInBefore ? AuthPages.signIn : AuthPages.signUp
      }
    },
    mounted() {

      if(this.isInAppView()) {
        this.$store.commit(StoreConstants.commits.setLoadedFromAndroidApp, true)

        this.loading = true


        let usingSecureTokenPass = this.$route.query.secureTokenPass

        if(!usingSecureTokenPass) {
          // we can't get rid of this, as the accounts page still opens
          //  using query params
          let idToken = this.$route.query.idToken
          let domain = this.$route.query.domain
          let expiresAt = this.$route.query.expiresAt ? parseInt(this.$route.query.expiresAt) : 0
          let account = this.$route.query.accountId
          let deviceId = this.$route.query.deviceId
          let deviceUUID = this.$route.query.deviceUUID
          let redirectUrl = this.$route.query.redirect
          let showOwnScreenshots = this.$route.query.showOwnScreenshots

          this.loginUsingPassedCredentials(idToken, domain, expiresAt, account, deviceId, deviceUUID, redirectUrl, showOwnScreenshots)
        } else {

          console.log('secure token pass')

          let credentialsJsonString = AndroidInterface.getCredentials()

          console.log(credentialsJsonString)

          let c = JSON.parse(credentialsJsonString)

          this.loginUsingPassedCredentials(
            c.token,
            c.domain,
            c.expiresAt,
            c.accountId,
            c.deviceId,
            c.deviceUUID,
            c.redirect,
            c.showOwnScreenshots
          )
          if(AndroidInterface.canUseGooglePlayForPurchases()) {
            AndroidInterface.checkForFailedGooglePlayPurchases()
          }
        }

      } else {
        this.$store.commit(StoreConstants.commits.setLoadedFromAndroidApp, false)

        console.log('checking current authenticated user')

        const checkAuth = async () => {
          try {
            let currentUser = await Auth.currentAuthenticatedUser()
            console.log('AUTHENTICATED')
            console.log(currentUser)

            if(!currentUser.signInUserSession.idToken.payload.email_verified) {
              await Auth.signOut() // sign them out, make them sign in again and on sign in they'll be asked to verify the email address.
            } else {
              await this.onAuthentication(currentUser.signInUserSession)
            }

          } catch(e) {
            console.log('NOT AUTHENTICATED')
            console.log(e)

            if(e !== 'The user is not authenticated') {
              this.error = true
              if(e.message) {
                this.errorMessage = e.message
              } else {
                this.errorMessage = e.toString()
              }

              await Auth.signOut()
            }

          } finally {
            SegmentTracking.page('Login')
          }
        }

        checkAuth()
      }
    }
  }
</script>

<style>

</style>
